import { $Http } from '@/utils/http/axios';

const Api = {
  getIndustryLists: '/api/service/category/list',
  getMoneyLimit: '/api/task/info/config'
};

export function getIndustryLists(params = { level: 2 }) {
  return $Http.get({ url: Api.getIndustryLists, params });
}

export function getMoneyLimit(params) {
  return $Http.get({ url: Api.getMoneyLimit, params });
}
