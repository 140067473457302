<template>
  <div class="collapse-item">
    <div class="collapse-item-title" @click="handleHeaderClick">
      <slot name="title"></slot>
      <Isvg icon-class="arrow-to-bottom" :class="{ 'arrow-top-top': isActive }" />
    </div>
    <div v-if="activeItem" class="collapse-item-active">{{ activeItem }}</div>
    <CollapseTransition>
      <div class="collapse-item-wrap" v-show="isActive">
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
  import CollapseTransition from './CollapseTransition';

  export default {
    name: 'CollapseItem',
    components: { CollapseTransition },
    data() {
      return {
        isActive: true,
        isActiveItem: ''
      };
    },
    props: {
      activeItem: {
        type: [String, Number, Array],
        default: ''
      }
    },
    watch: {
      activeItem(val) {
        if (!val) return;
        this.handleHeaderClick();
      }
    },
    methods: {
      handleHeaderClick() {
        this.isActive = !this.isActive;
      }
    }
  };
</script>

<style lang="less" scoped>
  .collapse-item {
    &-title {
      .pointer;
      .font-16;
      .weight-600;
      line-height: 30px;
      height: 30px;
      .flex;
      justify-content: space-between;
      align-items: center;

      .arrow-top-top {
        transform: rotate(180deg);
      }
    }

    &-active {
      line-height: 30px;
      .font-14;
    }

    &-wrap {
      .content {
        padding-top: 10px;
      }
    }
  }
</style>
