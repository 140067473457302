<!--// https://www.gogowork.cn/api/task/info/config-->
<template>
  <aside class="aside">
    <div class="aside-title">分类搜索</div>
    <div class="aside-industry">
      <CollapseItem :activeItem="activeItemMap.industry.name" ref="collapseIndustryItem">
        <template slot="title">行业分类</template>
        <div
          v-for="(item, index) in industryLists"
          :key="index"
          class="industry-item"
          :class="{ 'industry-active': activeItemMap.category.index === index }"
          @click="itemClickHandler({ id: item.id, name: item.name }, index, 'industry')"
        >
          {{ item.name }}
        </div>
      </CollapseItem>
    </div>
    <div class="aside-category" v-show="isShowCategory">
      <CollapseItem :activeItem="activeItemMap.category.name" ref="collapseCategoryItem">
        <template slot="title">类别</template>
        <div
          v-for="(item, index) in categoryLists"
          :key="index"
          class="category-item"
          :class="{ 'industry-active': activeItemMap.category.index === index }"
          @click="itemClickHandler({ id: item.id, name: item.name }, index, 'category')"
        >
          {{ item.name }}
        </div>
      </CollapseItem>
    </div>
    <div class="aside-level">
      <CollapseItem>
        <template slot="title">技能等级</template>
        <div class="level-radio-wrapper">
          <RadioItem
            v-for="(item, index) in levelList"
            :key="index"
            name="level"
            :value="item.id"
            @input="checkLevelHandler"
            ref="radioLevelItem"
          >
            {{ item.name }}
          </RadioItem>
        </div>
      </CollapseItem>
    </div>
    <div class="aside-charge">
      <CollapseItem>
        <template slot="title">每小时计费</template>
        <PriceInput
          :validateRulesMM="hourly_budget"
          @priceInputHandler="collectDate"
          ref="priceInput"
        />
      </CollapseItem>
    </div>
    <div class="aside-time">
      <CollapseItem>
        <template slot="title">入驻时间</template>
        <div class="time-radio-wrapper">
          <RadioItem
            v-for="(item, index) in regAtTime"
            :key="index"
            name="settle"
            :value="item.id"
            @input="checkTimeHandler"
          >
            {{ item.name }}
          </RadioItem>
        </div>
      </CollapseItem>
    </div>
  </aside>
</template>

<script>
  import CollapseItem from './CollapseItem';
  import PriceInput from './PriceInput';
  import RadioItem from './RadioItem';
  import { getIndustryLists, getMoneyLimit } from '@/_api/sys/aside';

  export default {
    name: '',
    data() {
      return {
        levelList: [
          {
            id: 'ALL',
            name: '全部'
          },
          {
            id: '1',
            name: '初级'
          },
          {
            id: '2',
            name: '中级'
          },
          {
            id: '3',
            name: '高级'
          }
        ],
        regAtTime: [
          {
            id: 'ALL',
            name: '全部'
          },
          {
            id: '1',
            name: '半年及以内'
          },
          {
            id: '2',
            name: '半年以上 - 一年及以内'
          },
          {
            id: '3',
            name: '1年以上 - 3年及以内'
          },
          {
            id: '4',
            name: '3年以上'
          }
        ],
        activeItemMap: {
          industry: {
            index: '',
            name: ''
          },
          category: {
            index: '',
            name: ''
          }
        },
        hourly_budget: [],
        selectMapDate: {},
        industryLists: [],
        isShowCategory: false
      };
    },
    props: {},
    components: { CollapseItem, RadioItem, PriceInput },
    watch: {},
    computed: {
      categoryLists() {
        const list = this.industryLists.filter(
          (item) => item.name === this.activeItemMap.industry.name
        );
        return list[0]?.children;
      }
    },
    filters: {},
    created() {
      this.fetchIndustryLists();
      this.getMoneyLimit();
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
      checkLevelHandler(value) {
        let label =
          value === 'ALL'
            ? ''
            : (this.levelList.filter((item) => item.id === value) || [])[0]?.name;
        this.collectDate({
          freelancer_level: value === 'ALL' ? '' : value,
          freelancer_level_label: label
        });
      },
      async getMoneyLimit() {
        const { data } = await getMoneyLimit();
        console.log();
        const { hourly_budget } = data;
        this.hourly_budget = hourly_budget;
      },
      checkTimeHandler(value) {
        let label =
          value === 'ALL'
            ? ''
            : (this.regAtTime.filter((item) => item.id === value) || [])[0]?.name;
        this.collectDate({ settle: value === 'ALL' ? '' : value, settle_label: label });
      },
      itemClickHandler({ id, name }, index, cate) {
        if (cate === 'industry') {
          this.activeItemMap['category'].name = '';
          this.isShowCategory = true;
          this.$refs.collapseCategoryItem.isActive = true;
        }
        this.activeItemMap[cate].index = index;
        this.activeItemMap[cate].name = name;
        const skill_cat_id_label = this.activeItemMap['category'].name
          ? this.activeItemMap['industry'].name + '/' + this.activeItemMap['category'].name
          : this.activeItemMap['industry'].name;
        this.collectDate({ skill_cat_id: id, skill_cat_id_label });
      },
      async fetchIndustryLists() {
        const { data } = await getIndustryLists();
        this.industryLists = data;
      },
      collectDate({
        hourly_rate = this.selectMapDate?.hourly_rate,
        skill_cat_id = this.selectMapDate?.skill_cat_id,
        skill_cat_id_label = this.selectMapDate?.skill_cat_id_label,
        freelancer_level = this.selectMapDate?.freelancer_level,
        freelancer_level_label = this.selectMapDate?.freelancer_level_label,
        settle_label = this.selectMapDate?.settle_label,
        settle = this.selectMapDate?.settle
      }) {
        this.selectMapDate = {
          ...this.selectMapDate,
          hourly_rate,
          skill_cat_id,
          skill_cat_id_label,
          freelancer_level,
          freelancer_level_label,
          settle,
          settle_label
        };
        this.$emit('getSelectData', this.selectMapDate);
      },
      resetAsideList(value) {
        if (value === 'industry') {
          this.activeItemMap.industry.name = '';
          this.activeItemMap.industry.index = '';
          this.activeItemMap.category.index = '';
          this.activeItemMap.category.name = '';
          this.isShowCategory = false;
          this.$refs.collapseIndustryItem.isActive = true;
          this.collectDate({ skill_cat_id: '', skill_cat_id_label: '' });
        }
        if (value === 'level') {
          document.querySelector("input[type='radio'][name='level'][value='ALL']").checked = true;
          this.collectDate({ freelancer_level: '', freelancer_level_label: '' });
        }
        if (value === 'price') {
          this.$refs.priceInput.low = '';
          this.$refs.priceInput.high = '';
          this.collectDate({ hourly_rate: [] });
        }
        if (value === 'settle') {
          document.querySelector("input[type='radio'][name='settle'][value='ALL']").checked = true;
          this.collectDate({ settle: '', settle_label: '' });
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .aside {
    width: 320px;
    background: #ffffff;
    box-shadow: 0px 1px 2px 1px rgba(220, 221, 221, 0.5);
    padding: 20px;

    .aside-title {
      .font-18;
      line-height: 20px;
      padding-bottom: 10px;
      position: relative;
      .border-bottom-1px(1px);
      //.weight-600;
    }

    .aside-industry,
    .aside-level,
    .aside-category,
    .aside-charge {
      padding: 20px 0;
      position: relative;
      .border-bottom-1px(1px);
    }

    .aside-industry,
    .aside-category {
      .industry-item,
      .category-item {
        line-height: 30px;
        .font-14;
        .pointer;
        background-color: #fff;
        color: #575757;
        transition: all 0.3s ease;

        &:hover {
          color: @color-blue;
          background-color: #f8f8f8;
        }
      }

      .industry-active {
        color: @color-blue;
        background-color: #f8f8f8;
      }
    }

    .aside-level {
      .level-radio-wrapper {
        .flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .aside-time {
      padding: 20px 0;
      position: relative;

      .time-radio-wrapper > div {
        padding: 5px 0;
      }
    }
  }
</style>
