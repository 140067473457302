<template>
  <div class="freelancer">
    <FreelancerBanner />
    <FreelancerRecommend
      :info="personLists"
      :isFetchingPerson="isFetchingPerson"
      @fetchPersonLists="fetchPersonLists"
    />
    <div class="freelancer-content">
      <AsideTask ref="asideTask" @getSelectData="getSelectData" />
      <div class="content-lists">
        <SearchInput @inputHandler="getInputContent" />
        <div class="lists-history" ref="listsHistory" v-if="historyTag.some((i) => i.isShow)">
          <span v-show="item.isShow" v-for="(item, index) in historyTag" :key="index"
            >{{ item.label }}<i @click="clearHistoryTag(item.id)"></i
          ></span>
        </div>
        <div class="lists-list">
          <div class="list-top">
            <div>
              <span>共找到{{ freelancerTotal }}个人才</span>
              <span>每页显示10个结果</span>
            </div>
            <DropDown :list="dropDownList" @selectSortHandler="selectSortHandler" />
          </div>
          <div class="list-content">
            <div class="person" v-for="(item, index) in freelancerList.list" :key="index">
              <TalentsItem :list="item" mode="talent" />
            </div>
            <el-empty v-if="freelancerList.list.length==0" description="没有找到此检索人才,换个检索词试试？"/>
            <div class="pagination">
              <Pagination
                :page-size="10"
                prev-text="上一页"
                next-text="下一页"
                :total="freelancerTotal"
                layout="prev, pager, next"
                :hide-on-single-page="true"
                @current-change="currentPageHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { AsideTask } from '@/_components/Aside';
  import { FreelancerBanner, FreelancerRecommend, DropDown } from '@/_components/Pages/freelancer';
  import { getFreelancerList, getPersonLists } from '@/_api/freelancer';
  import { cloneDeep } from 'lodash-es';
  import { SearchInput } from '@/_components/SearchInput';
  import { TalentsItem } from '@/_components/PersonItem';
  import { Pagination } from 'element-ui';

  export default {
    name: '',
    data() {
      return {
        page: 1,
        maxPage: 0,
        freelancerPage: 1,
        personLists: [],
        isFetchingPerson: false,
        searchParams: {
          page_size: 10,
          page: 1,
          sort_type: 0 // 默认
        },
        freelancerList: {
          list: []
        },
        dropDownList: ['综合排序', '热门推荐']
      };
    },
    props: {},
    components: {
      FreelancerBanner,
      FreelancerRecommend,
      AsideTask,
      SearchInput,
      DropDown,
      TalentsItem,
      Pagination
    },
    watch: {
      searchParams: {
        deep: true,
        handler(val) {
          const _temp = cloneDeep(val);
          if (Reflect.has(_temp, 'skill_cat_id_label')) {
            Reflect.deleteProperty(_temp, 'skill_cat_id_label');
          }
          if (Reflect.has(_temp, 'freelancer_level_label')) {
            Reflect.deleteProperty(_temp, 'freelancer_level_label');
          }
          if (Reflect.has(_temp, 'settle_label')) {
            Reflect.deleteProperty(_temp, 'settle_label');
          }
          this.fetchFreelancerList(_temp);
        }
      },
      isLogin(newv){
        this.fetchFreelancerList()
      }
    },
    computed: {
      isLogin(){
        return !!this.$store.getters['useUserStore/getToken'];
      },
      historyTag() {
        const {
          skill_cat_id_label = '',
          freelancer_level_label = '',
          hourly_rate = [],
          settle_label = ''
        } = this.searchParams;
        const tags = [
          {
            isShow: !!skill_cat_id_label,
            id: 'industry',
            label: `类别：${skill_cat_id_label}`
          },
          {
            isShow: !!freelancer_level_label,
            id: 'level',
            label: `技能等级：${freelancer_level_label}`
          },
          {
            isShow: (hourly_rate || []).length > 0,
            id: 'price',
            label: `每小时费用：${(hourly_rate || [])[0]}~${(hourly_rate || [])[1]}元`
          },
          {
            isShow: !!settle_label,
            id: 'settle',
            label: `入驻时间：${settle_label}`
          }
        ];
        return tags;
      },
      freelancerTotal() {
        return this.freelancerList?.pages?.total;
      }
    },
    created() {
      this.fetchPersonLists();
      this.fetchFreelancerList();
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
      async fetchPersonLists(page = this.page, page_size = 4) {
        this.isFetchingPerson = true;
        const { data } = await getPersonLists({ page, page_size });
        this.personLists = data;
        this.isFetchingPerson = false;
      },
      getSelectData({
        hourly_rate = this.selectMapDate?.hourly_rate,
        skill_cat_id = this.selectMapDate?.skill_cat_id,
        skill_cat_id_label = this.selectMapDate?.skill_cat_id_label,
        freelancer_level = this.selectMapDate?.freelancer_level,
        freelancer_level_label = this.selectMapDate?.freelancer_level_label,
        settle_label = this.selectMapDate?.settle_label,
        settle = this.selectMapDate?.settle,
        sort_type = this.searchParams?.sort_type,
        kw = this.searchParams?.kw
      }) {
        this.searchParams = {
          ...this.searchParams,
          hourly_rate,
          skill_cat_id,
          skill_cat_id_label,
          freelancer_level,
          freelancer_level_label,
          settle,
          settle_label,
          sort_type,
          kw
        };
      },
      clearHistoryTag(id) {
        this.$refs.asideTask.resetAsideList(id);
      },
      getInputContent(val) {
        console.log(val);
        this.searchParams = {
          ...this.searchParams,
          kw: val
        };
      },
      async fetchFreelancerList(params = this.searchParams) {
        const { data } = await getFreelancerList(params);
        this.freelancerList = data;
      },
      currentPageHandler(index) {
        this.freelancerPage = index;
        this.searchParams.page = index;
      },
      selectSortHandler(value) {
        const map = new Map();
        map.set('热门推荐', 1);
        map.set('综合排序', 0);
        this.searchParams.sort_type = map.get(value);
      }
    }
  };
</script>

<style lang="less" scoped>
  .freelancer {
    background-color: #f8f8f8;
    width: 100vw;
    min-height: 100vh;
    min-width: @main-min-width;
    padding-bottom: 70px;

    &-content {
      .flex;
      align-items: flex-start;
      .main-width-center;
      margin-top: 18px;
      justify-content: space-between;

      .content-lists {
        width: 860px;

        .lists-search {
        }

        .lists-history {
          .flex;
          flex-wrap: wrap;
          margin-top: 20px;

          & > span {
            display: inline-block;
            padding: 0 50px 0 10px;
            height: 20px;
            line-height: 20px;
            background-color: @color-blue;
            color: #fff;
            .font-12;
            border-radius: 10px;
            position: relative;

            & > i {
              .absolute;
              display: inline-block;
              width: 20px;
              height: 100%;
              line-height: 100%;
              right: 10px;
              text-align: center;
              .pointer;

              &::before {
                content: '\03A7';
                width: 20px;
                height: 20px;
                line-height: 20px;
              }
            }
          }

          span + span {
            margin-left: 20px;
          }
        }

        .lists-list {
          margin-top: 20px;
          background-color: #fff;

          .list-top {
            .flex;
            justify-content: space-between;
            padding: 20px 20px 10px 40px;
            border-bottom: 1px solid #dcdddd;

            & > div > span:first-of-type {
              .font-14;
              color: #000000;
            }

            & > div > span:last-of-type {
              .font-12;
              color: #575757;
              margin-left: 10px;
            }
          }

          .list-content {
            min-height: 580px;
            .person {
              position: relative;
              .border-bottom-1px(1px);
              :hover {
                background-color: #F8F8F8 !important;
              }
            }
            .pagination {
              text-align: right;
              padding: 16px 0 24px;
            }
          }
        }
      }
    }

    .test {
      width: 120px;
      height: 120px;
    }

    .test1 {
      width: 150px;
      height: 150px;
    }
  }
</style>
