<template>
  <div class="price-input">
    <div class="left">
      <div class="left-input">
        <input
          type="number"
          :placeholder="placeholder[0]"
          v-model="low"
          :class="{ error: !!errMessage[0] }"
          @blur="blurHandler"
        />
        <span>元</span>
      </div>
      <p class="error-info">{{ errMessage[0] }}</p>
    </div>
    <span>至</span>
    <div class="right">
      <div class="right-input">
        <input
          type="number"
          :placeholder="placeholder[1]"
          v-model="high"
          :class="{ error: !!errMessage[1] }"
          @blur="blurHandler"
        />
        <span>元</span>
      </div>
      <p class="error-info">{{ errMessage[1] }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PriceInput',
    data() {
      return {
        placeholder: ['￥最低', '￥最高'],
        low: '',
        high: '',
        errMessage: ['', ''],
        isOnBlur: false
      };
    },
    props: {
      validateRulesMM: {
        type: Array,
        default: () => []
      }
    },
    watch: {
      low(val) {
        if (val === '') return;
        if (val < this.validateRulesMM[0]) {
          this.errMessage[0] = `最低不能小于${this.validateRulesMM[0]}元`;
        } else if (val > this.validateRulesMM[1]) {
          this.errMessage[0] = `最低不能大于${this.validateRulesMM[1]}元`;
        } else {
          this.errMessage[0] = '';
        }
      },
      high(val) {
        // if (typeof val !== 'number') return;
        if (val > this.validateRulesMM[1]) {
          this.errMessage[1] = `最高不能大于${this.validateRulesMM[1]}元`;
        } else {
          this.errMessage[1] = '';
        }
      }
    },
    methods: {
      priceInputHandler() {
        if (this.errMessage.every((i) => !i)) {
          this.$emit('priceInputHandler', { hourly_rate: [this.low, this.high] });
        }
      },
      blurHandler() {
        this.isOnBlur = !!this.low && !!this.high;
        this.isOnBlur && this.priceInputHandler();
      }
    }
  };
</script>

<style lang="less" scoped>
  .price-input {
    .flex;
    justify-content: space-between;
    align-items: stretch;

    & > span {
      color: #575757;
      .font-12;
      padding-top: 6px;
    }

    .left,
    .right {
      width: 120px;

      &-input {
        height: 24px;
        .relative;

        input {
          width: 100%;
          height: 100%;
          padding-left: 10px;

          &:focus {
            border: 1px solid @color-blue;
          }
        }
      }

      .error {
        border: 1px solid #ff008e;
      }

      .error-info {
        .font-12;
        line-height: 16px;
        color: #ff008e;
        word-break: keep-all;
        padding-top: 4px;
      }

      span {
        .absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        .font-12;
        color: #b5b5b5;
        align-self: center;
      }
    }
  }
</style>
