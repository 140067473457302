<template>
  <div class="search-input">
    <div class="content">
      <input type="text" v-model="value" :placeholder="placeholder" />
      <Isvg icon-class="search-01" class-name="icon" @click.native="inputHandler" />
    </div>
    <div class="button" @click="inputHandler"> 搜索 </div>
  </div>
</template>

<script>
  export default {
    name: 'SearchInput',
    data() {
      return {
        value: '',
        placeholder: '关键字'
      };
    },
    methods: {
      inputHandler() {
        this.$emit('inputHandler', this.value);
      }
    }
  };
</script>

<style lang="less" scoped>
  @height: 40px;
  .search-input {
    .flex;
    height: @height;
    box-shadow: 0px 1px 4px 2px rgba(220, 221, 221, 0.5);

    .content {
      height: @height;
      width: 710px;
      position: relative;

      input {
        height: @height;
        width: 100%;
        padding-left: 53px;
        outline: none;
        border: none;
        color: #000000;
        .font-14;
        transition: border 0.3s ease;
        border: 1px solid transparent;
        &:focus {
          border: 1px solid @color-blue;
        }
      }

      .icon {
        .absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        .pointer;
      }
    }

    .button {
      width: 150px;
      height: @height;
      line-height: @height;
      text-align: center;
      background-color: @color-blue;
      color: #fff;
      .pointer;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.7;
      }
    }
  }
</style>
