<template>
  <div>
    <label
      ><input
        :name="name"
        type="radio"
        v-model="checkValue"
        @change="checkHandler"
        :value="value" /><span class="label-circle"><slot></slot></span
    ></label>
  </div>
</template>

<script>
  export default {
    name: 'RatioItem.vue',
    data() {
      return {
        checkValue: 'ALL'
      };
    },
    props: {
      name: {
        type: String
      },
      value: {
        type: String,
        default: 'ALL'
      }
    },
    watch: {},
    methods: {
      checkHandler(e) {
        const { defaultValue: id } = e.target;
        this.$emit('input', id);
      }
    }
  };
</script>

<style lang="less" scoped>
  .label-circle {
    line-height: 20px;
    display: block;
    position: relative;
    padding-left: 20px;
    box-sizing: border-box;
    color: #575757;
    .font-12;
  }

  .label-circle::after {
    content: '';
    border: 1px solid #dcdddd;
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    left: 0;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  .label-circle::before {
    content: '';
    background: @color-blue;
    border-radius: 50%;
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 3px;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  label {
    .pointer;
  }

  label input {
    display: none;
  }

  label:hover .label-circle:after {
    border: 1px solid @color-blue;
    transition: border 0.5s ease;
  }

  label input:checked + .label-circle:after {
    border-color: @color-blue;
  }

  label input:checked + .label-circle:before {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
</style>
